import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { Container } from "react-bootstrap"

// markup
export default function NotFoundPage(){
  return (
    <Layout>
      <Container>
        <h1>404</h1>
      </Container>
    </Layout>
  )
}
